import { IMyOrderDetails, IUser } from "shared/Types/appTypes"
import { ITransactionResponse } from "./transactionTypes"
import { objectHas } from "shared/Helpers/lang"
import { CircularProgressProps } from "@material-ui/core"
import { DateTime, Duration } from "luxon"

interface IPaymentPendingResponse {
    readonly paymentDetails: {
        readonly accepted: false
    }
}

export interface IPaymentSuccessResponse {
    readonly paymentDetails: {
        readonly accepted: true
        readonly orderId: string
    }
    readonly order?: IMyOrderDetails
    readonly userAccountDetails?: IUser
    readonly transactionResponse?: ITransactionResponse
}

export type IPaymentResponse = IPaymentPendingResponse | IPaymentSuccessResponse;

export function isPaymentSuccess(response: IPaymentResponse): response is IPaymentSuccessResponse {
    return (
        objectHas("paymentDetails", response) &&
        response.paymentDetails &&
        objectHas("accepted", response.paymentDetails) &&
        response.paymentDetails.accepted
    )
}

export enum PaymentStatus {
    PENDING = "PENDING",
    IN_PROGRESS = "IN_PROGRESS",
    COMPLETED = "COMPLETED",
    FAILED = "FAILED",
}

export enum PaymentErrorType {
    QUERY_ERROR = "QUERY_ERROR",
    TIMEOUT = "TIMEOUT",
}

type PaymentPending = Readonly<{
    status: PaymentStatus.PENDING
    final: false
}>

type PaymentInProgress = Readonly<{
    status: PaymentStatus.IN_PROGRESS
    final: false
}>

type PaymentCompleted = Readonly<{
    status: PaymentStatus.COMPLETED
    final: true
    response: IPaymentSuccessResponse
}>

type PaymentFailed = Readonly<{
    status: PaymentStatus.FAILED
    final: true
    errorType: PaymentErrorType
    error: unknown
}>

export type PaymentState = PaymentPending | PaymentInProgress | PaymentCompleted | PaymentFailed

export type ProgressState = Readonly<{
    startTime: DateTime
    endTime: DateTime
    remaining: Duration
    progress: number
}>
