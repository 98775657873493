import { DateTime, Duration } from "luxon"
import { useCallback, useMemo, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Logger } from "shared/Helpers/logging"
import { useEffectOnce } from "shared/Helpers/reactHooks"
import { selectPaymentConfig } from "../Properties/propertySelectors"
import { CurrentEnvironment } from "../Environment/envTypes"
import { ApiResponse, ApiResponseTypes } from "shared/Types/responseTypes"
import { IPaymentResponse, isPaymentSuccess, PaymentErrorType, PaymentState, PaymentStatus, ProgressState } from "./paymentTypes"
import { get } from "shared/Helpers/http"
import { useToken } from "../Login/useToken"
import { useEnvironment } from "../Environment/envHooks"
import { useApiCall } from "../Query/useApiCall"
import { setUserAccountBalance } from "../User/userActions"
import { useSuccessModal } from "./transactionHooks"
import { useAppErrorHandling } from "../Error/errorHooks"
import { useLocalization } from "@fluent/react"
import { ErrorDisplay } from "../Error/errorTypes"
import { sleep } from "shared/Helpers/lang"
import { useDateTime } from "../Localization/useDateTime"
import { useInterval } from "shared/hooks/useInterval"

const DEFAULT_POLL_INTERVAL_IN_MILLIS = 500
const DEFAULT_MAX_POLLING_TIME_IN_SECONDS = 30

const fetchPaymentStatus = (
    orderUid: string,
    token: string,
    environment: CurrentEnvironment
): Promise<ApiResponse<IPaymentResponse>> => {
    const pathPrefix = token ? "" : "/public"
    const url = `${pathPrefix}/orders/byUUID/${orderUid}/payment`

    return get(url, {
        token,
        environment,
    })
}

// NOTE: We want to handle network problems like timeouts manually
const manuallyHandledResponseTypes = [ApiResponseTypes.NETWORK_ERROR]

export function usePaymentFlow() {
    const mounted = useRef(true)

    const [paymentState, setPaymentState] = useState<PaymentState>({
        status: PaymentStatus.PENDING,
        final: false,
    })

    const { l10n } = useLocalization()
    const paymentConfig = useSelector(selectPaymentConfig)
    const dispatch = useDispatch()
    const dispatchSuccess = useSuccessModal()
    const { dispatchGeneralError } = useAppErrorHandling()
    const dateTimeFactory = useDateTime()

    const authToken = useToken()
    const { currentEnv } = useEnvironment()

    const { callForResult: callFetchPaymentStatus, handleCallError } = useApiCall(fetchPaymentStatus, {
        manuallyHandledResponseTypes,
    })

    const delayInMillis = paymentConfig?.pollIntervalInMillis ?? DEFAULT_POLL_INTERVAL_IN_MILLIS
    const timeout = useMemo(
        () =>
            Duration.fromObject({
                seconds: paymentConfig?.maxPollingTimeInSeconds ?? DEFAULT_MAX_POLLING_TIME_IN_SECONDS,
            }),
        [paymentConfig?.maxPollingTimeInSeconds]
    )

    const [progressState, setProgressState] = useState<ProgressState>()

    const handlePaymentFinished = useCallback(
        async (paymentState: PaymentState, flowType: string | undefined) => {
            const logger = new Logger("payment-flow")

            if (paymentState.status === PaymentStatus.COMPLETED) {
                let successShown = false
                const response = paymentState.response

                try {
                    const accountBalance =
                        response.userAccountDetails?.accountBalance ?? response.order?.customer?.accountBalance
                    if (accountBalance) {
                        dispatch(setUserAccountBalance(accountBalance))
                    } else {
                        logger.info("User account balance missing [cannot update wallet balance]")
                    }

                    if (flowType === "webshop") {
                        logger.info(`Detected shop purchase (flow type: ${flowType}) [show proof of purchase]`)

                        dispatchSuccess({
                            title:
                                response.transactionResponse?.subject ??
                                l10n.getString("quickpay-generic-success", null, "Din transaktion blev gennemført"),
                            message: response.transactionResponse?.message ?? "",
                            masterOrder: response.order,
                        })

                        successShown = true
                    } else {
                        logger.info(`Not a shop purchase (flow type: ${flowType}) [show generic success dialog]`)
                    }
                } catch (error: unknown) {
                    handleCallError(error, "handling transaction success")
                    logger.info(
                        `Failed to handle poller response [show generic success instead]`
                    )
                }

                if (!successShown) {
                    dispatchSuccess({
                        title:
                            response.transactionResponse?.subject ??
                            l10n.getString("quickpay-generic-success", null, "Din transaktion blev gennemført"),
                        message: response.transactionResponse?.message ?? "",
                    })
                }
            } else if (paymentState.status === PaymentStatus.FAILED) {
                const translationId = (paymentState.errorType === PaymentErrorType.TIMEOUT) ? "quickpay-finalization-error-timeout" : "quickpay-finalization-error-other"
                const message = l10n.getString(translationId, null, "Der opstod en fejl")
                dispatchGeneralError(message, ErrorDisplay.Route)
            }
        },
        [dispatch, dispatchSuccess, dispatchGeneralError, handleCallError, l10n]
    )

    const pollForPaymentState = useCallback(
        async (orderUid: string) => {
            const logger = new Logger("payment-flow")

            const startTime = DateTime.now()
            const endTime = startTime.plus(timeout)
            let done = false

            logger.info(`Start polling for payment state [startTime: ${startTime.toISO()}, endTime: ${endTime.toISO()}, timeout: ${timeout.toISO()}]`)
            setPaymentState({
                status: PaymentStatus.IN_PROGRESS,
                final: false,
            })
            setProgressState({
                startTime,
                endTime,
                remaining: timeout,
                progress: 100,
            })

            while (mounted.current && !done) {
                try {
                    const currentTime = DateTime.now()
                    // Check if the polling timeout has been reached
                    if (currentTime >= endTime) {
                        logger.info("Timeout during payment polling [show error]")
                        setPaymentState({
                            status: PaymentStatus.FAILED,
                            final: true,
                            errorType: PaymentErrorType.TIMEOUT,
                            error: undefined,
                        })
                        done = true
                        continue // skip poll
                    }

                    logger.info("Fetching payment status")
                    const response = await callFetchPaymentStatus(orderUid, authToken, currentEnv)

                    if (isPaymentSuccess(response)) {
                        logger.info("Payment complete")
                        setPaymentState({
                            status: PaymentStatus.COMPLETED,
                            final: true,
                            response,
                        })
                        done = true
                        continue // skip sleep
                    }

                    await sleep(delayInMillis)
                } catch (err: unknown) {
                    const manuallyHandledError = handleCallError(err, "polling transaction status")
                    if (manuallyHandledError) {
                        logger.info("Got network error while polling [ignore and continue polling]")
                        // NOTE: We intentionally skip the sleep in this case
                    } else {
                        logger.error("Payment polling failed", err)
                        setPaymentState({
                            status: PaymentStatus.FAILED,
                            final: true,
                            errorType: PaymentErrorType.QUERY_ERROR,
                            error: err,
                        })
                        done = true
                    }
                }
            }
        },
        [setPaymentState, callFetchPaymentStatus, authToken, currentEnv, handleCallError]
    )

    useInterval(() => {
        setProgressState((prev) => {
            if (!prev) return prev

            const { startTime, endTime } = prev
            const now = DateTime.now()
            const zeroDuration = Duration.fromMillis(0)

            const totalMillis = timeout.as("milliseconds")
            const timeLeftInMillis = endTime.diff(now, "milliseconds").as("milliseconds")
            const remainingPercentage = (timeLeftInMillis / totalMillis) * 100

            return {
                startTime,
                endTime,
                remaining: endTime > now ? endTime.diff(now, "seconds") : zeroDuration,
                progress: remainingPercentage,
            }
        })
    }, paymentState.status === PaymentStatus.IN_PROGRESS ? 500 : null)

    useEffectOnce(() => {
        return () => {
            const logger = new Logger("payment")
            logger.info("Payment components unmounted [stop polling]")
            mounted.current = false
        }
    })

    return {
        paymentState,
        progressState,
        pollForPaymentState,
        handlePaymentFinished,
    }
}
