import { useState } from "react"
import { useDispatch } from "react-redux"
import { ApiResponse, ApiResponseTypes, Unresponsify } from "shared/Types/responseTypes"
import { Unpromisify } from "shared/Types/helperTypes"
import { logout } from "shared/Modules/User/userActions"
import { ErrorDisplay } from "shared/Modules/Error/errorTypes"
import { useAppErrorHandling } from "shared/Modules/Error/errorHooks"
import { logQuery } from "./queryActions"
import { QueryError } from "./queryTypes"
import { Logger } from "shared/Helpers/logging"

type UseApiCallOptions = Readonly<{
    errorDisplay?: ErrorDisplay,
    manuallyHandledStatusCodes?: number[]
    manuallyHandledResponseTypes?: ApiResponseTypes[]
}>

interface UseApiCall<Fn extends (...args: any[]) => Promise<ApiResponse<any>>> {
    loading: boolean
    callForResult: (...args: Parameters<Fn>) => Promise<Unresponsify<Unpromisify<ReturnType<Fn>>>>
    callForAction: (...args: Parameters<Fn>) => Promise<void>
    handleCallError: (e: unknown, operation: string) => QueryError | undefined
}

export function useApiCall<Fn extends (...args: any[]) => Promise<ApiResponse<any>>>(api: Fn, options: UseApiCallOptions = {}): UseApiCall<Fn> {
    const {
        errorDisplay = ErrorDisplay.Dialog,
        manuallyHandledStatusCodes = [],
        manuallyHandledResponseTypes = [],
    } = options

    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const { dispatchApiError, dispatchInternalError, createQueryError } = useAppErrorHandling()

    const logger = new Logger("query")

    function callForResult(...args: Parameters<Fn>) {
        setLoading(true)

        return api(...args).then(response => {
            let shouldHandle = !manuallyHandledResponseTypes.includes(response.type)
            dispatch(logQuery(response))

            switch (response.type) {
                case ApiResponseTypes.SUCCESS:
                    return response.data
                case ApiResponseTypes.EMPTY_SUCCESS:
                case ApiResponseTypes.API_ERROR:
                case ApiResponseTypes.PROTOCOL_ERROR:
                    if (manuallyHandledStatusCodes.includes(response.responseCode)) {
                        shouldHandle = false
                    }

                    if (response.responseCode === 401 && shouldHandle) {
                        dispatch(logout())
                    }

                    // Fall through
                case ApiResponseTypes.NETWORK_ERROR:
                    if (shouldHandle) {
                        dispatchApiError(response, errorDisplay)
                    }

                    // NOTE: We always throw even if we don't handle the error
                    throw createQueryError(response)
            }
        }).finally(() => setLoading(false))
    }

    function callForAction(...args: Parameters<Fn>): Promise<void> {
        setLoading(true)

        return api(...args).then(response => {
            let shouldHandle = !manuallyHandledResponseTypes.includes(response.type)
            dispatch(logQuery(response))

            switch (response.type) {
                case ApiResponseTypes.SUCCESS:
                case ApiResponseTypes.EMPTY_SUCCESS:
                    return
                case ApiResponseTypes.API_ERROR:
                case ApiResponseTypes.PROTOCOL_ERROR:
                    if (manuallyHandledStatusCodes.includes(response.responseCode)) {
                        shouldHandle = false
                    }

                    if (response.responseCode === 401 && shouldHandle) {
                        dispatch(logout())
                    }

                    // Fall through
                case ApiResponseTypes.NETWORK_ERROR:
                    if (shouldHandle) {
                        dispatchApiError(response, errorDisplay)
                    }

                    throw createQueryError(response)
            }
        }).finally(() => setLoading(false))
    }

    function handleCallError(e: unknown, operation: string) {
        // Internal errors should be handled differently than API errors
        const internalErrorDisplay = (errorDisplay === ErrorDisplay.None || errorDisplay === ErrorDisplay.Snackbar) ? ErrorDisplay.Dialog : errorDisplay

        if (e instanceof QueryError) {
            if (manuallyHandledResponseTypes.includes(e.responseType)) {
                // Return error for manual handling
                return e
            } else if (e.httpStatusCodeIfPresent && manuallyHandledStatusCodes.includes(e.httpStatusCodeIfPresent)) {
                // Return error for manual handling
                return e
            } else {
                // Ignore: already handled by useApiCall
            }
        } else if (e instanceof Error) {
            logger.error(`Error while ${operation}`, e)
            dispatchInternalError(`Error while ${operation}: ${e.message}`, internalErrorDisplay)
        } else {
            logger.error(`Unknown error while ${operation}`, e)
            dispatchInternalError(`Unknown error while ${operation}`, internalErrorDisplay)
        }

        return undefined
    }

    return {
        loading,
        callForResult,
        callForAction,
        handleCallError,
    }
}
