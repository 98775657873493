import React, { useEffect, useState } from 'react'
import { useParams } from "react-router-dom";
import { Localized } from "@fluent/react";
import { useAppNavigation } from 'shared/hooks/useAppNavigation';
import { useUrlQueryParams } from 'shared/hooks/useUrlQueryParams';
import { useToken } from "shared/Modules/Login/useToken";
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { Player } from '@lottiefiles/react-lottie-player';
import Screen from 'shared/Components/Skeleton/Screen';
import GeneralErrorAnim from "shared/Modules/Error/Assets/general-error-lottie-anim.json"
import { CordovaStatus } from 'shared/Modules/Cordova/cordovaTypes';
import { useCordova } from 'shared/Modules/Cordova/hooks/useCordova';
import { useInAppBrowsing } from 'shared/Modules/Browser/Components/InAppBrowserProvider';
import { Logger } from 'shared/Helpers/logging';
import { getLocalizationIdForEnum } from 'shared/Modules/Localization/localization';
import { apiEnumToLowerCase } from 'shared/Helpers/ApiHelper';
import { ProcessingTransaction } from 'shared/Modules/Transaction/Components/ProcessingTransaction';
import { StandardTopbar } from 'shared/Components/Skeleton/TopBar';
import { usePaymentFlow } from 'shared/Modules/Transaction/paymentHooks';
import { PaymentStatus } from 'shared/Modules/Transaction/paymentTypes';

interface RouteParams {
    orderUid: string
}

enum FlowSource {
    MOBILE_APP = "mobile-app",
    MOBILE_WEB = "mobile-web",
}

enum FlowMatch {
    MATCH = "MATCH",
    SHOULD_BE_APP = "SHOULD_BE_APP",
    SHOULD_BE_WEB = "SHOULD_BE_WEB",
}

const useStyles = makeStyles(theme =>({
    container: {
        height: '70%',
        paddingBottom: 90
    },
}));

function QuickPayFinalized () {
    const [flowType, setFlowType] = useState<string>()
    const [flowSource, setFlowSource] = useState<string>()
    const [flowMatch, setFlowMatch] = useState<FlowMatch>()

    const { orderUid } = useParams<RouteParams>()
    const classes = useStyles()

    const token = useToken()
    const cordova = useCordova()
    const { browser } = useInAppBrowsing()
    const { isGuest, goToHome } = useAppNavigation()
    const [type, source] = useUrlQueryParams(["type", "source"])

    const {
        paymentState,
        progressState,
        pollForPaymentState,
        handlePaymentFinished,
    } = usePaymentFlow()

    const logger = new Logger("quickpay")

    useEffect(() => {
        if (browser) {
            logger.info("Payment finalized [dismiss in-app browser window]")
            browser.close()
        }
    }, [])

    useEffect(() => {
        if (type !== null) setFlowType(type)
        if (source !== null) setFlowSource(apiEnumToLowerCase(source))
    }, [type, source])

    useEffect(() => {
        if (cordova.status !== CordovaStatus.Initializing && flowSource !== undefined) {
            const isApp = cordova.status === CordovaStatus.DeviceReady
            const shouldBeApp = !isApp && flowSource === FlowSource.MOBILE_APP
            const shouldBeWeb = isApp && flowSource === FlowSource.MOBILE_WEB
            const match = shouldBeApp ? FlowMatch.SHOULD_BE_APP : (shouldBeWeb ? FlowMatch.SHOULD_BE_WEB : FlowMatch.MATCH)

            logger.info(`Matching flow source ${flowSource} against Cordova status ${cordova.status} [result: ${match}]`)

            setFlowMatch(match)
        } else {
            logger.info(`Cordova or flow source not initialized - await status change [cordova status: ${cordova.status}, flow status: ${flowSource}]`)
        }
    }, [cordova.status, flowSource])

    useEffect(() => {
        if (flowMatch === undefined) return

        if (flowMatch === FlowMatch.MATCH) {
            if (isGuest || token) {
                if (isGuest) logger.info("Flow match client and user is guest [start polling]")
                else logger.info("Flow match client and auth token available [start polling]")

                pollForPaymentState(orderUid)
            } else {
                logger.info("Flow match client but no auth token available [send user to login]")
                goToHome()
            }
        }
    }, [flowMatch])

    if (flowMatch !== undefined && flowMatch !== FlowMatch.MATCH) {
        return (
            <Screen name="quickpayView" showPrimaryTopBar onBackButtonPress={goToHome} backButtonIcon="cross" fitPage>
                <Grid container direction="column" wrap="nowrap" justifyContent="space-evenly" className={classes.container}>
                    <Grid item>
                        <Player autoplay loop controls={false} src={GeneralErrorAnim} />
                    </Grid>

                    <Grid item>
                        <Localized id={getLocalizationIdForEnum("quickpay-source-mismatch-title", flowMatch)}>
                            <Typography variant="h6" align="center" paragraph>
                                Gå til app
                            </Typography>
                        </Localized>
                    </Grid>

                    <Grid item>
                        <Localized id={getLocalizationIdForEnum("quickpay-source-mismatch-message", flowMatch)}>
                            <Typography variant="subtitle1" align="center" paragraph>
                                Din GoPay app åbnede ikke automatisk. Klik på knappen nedenfor for at fuldføre handlingen:
                            </Typography>
                        </Localized>
                    </Grid>
                </Grid>
            </Screen>
        )
    }

    useEffect(() => {
        if (paymentState.final) {
            handlePaymentFinished(paymentState, flowType)
        }
    }, [paymentState])

    return (
        <Screen
            name="quickpay-finalized"
            fitPage
            fullHeight
            alternativeHeaderElement={(
                <StandardTopbar onClose={goToHome} />
            )}
        >
            <ProcessingTransaction stage="postprocessing" progressState={progressState} />
        </Screen>
    )
}

export default QuickPayFinalized
