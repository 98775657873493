import clsx from "clsx"
import React, { createRef, useEffect, useMemo, useState } from "react"
import { Dialog, makeStyles } from "@material-ui/core"
import { Localized } from "@fluent/react"
import { BuyerParty, IMyOrderDetails, IOrderLine, PaymentMethodType } from "shared/Types/appTypes"
import { Logger } from "shared/Helpers/logging"
import Screen from "shared/Components/Skeleton/Screen"
import { StandardTopbar, StandardTopbarTitle } from "shared/Components/Skeleton/TopBar"
import SlideUpTransition from "shared/Components/Dialog/SlideUpTransition"
import { useEnvironment } from "shared/Modules/Environment/envHooks"
import { QueryResult } from "shared/Modules/Query/queryTypes"
import { useQueryWithOptions } from "shared/Modules/Query/useQuery"
import { DateTimeFactory, useDateTime } from "shared/Modules/Localization/useDateTime"
import { ErrorDisplay } from "shared/Modules/Error/errorTypes"
import ErrorHandlerModal from "shared/Modules/Error/Components/ErrorHandlerModal"
import { useToken } from "shared/Modules/Login/useToken"
import { COMPANY_PAYMENT_METHOD } from "shared/Modules/Transaction/paymentLib"
import { getOrderDetailsIsomorphic, OrderDetailsResponse } from "../orderDetailsAPI"
import { Comment, HeaderSection, ReceiptContainer } from "../Components/ReceiptBlocks"
import { getPaymentMethodType, PurchaseDetailsSection } from "../Components/PurchaseDetailsSection"
import { DeliveryDetailsSection } from "../Components/DeliveryDetailsSection"
import { FlatOrderLineGroup, OrderDetailsSection } from "../Components/OrderDetailsSection"
import { ActionsSection } from "../Components/ActionsSection"
import { ForwardReceiptDrawer } from "../Components/ForwardReceiptDrawer"
import { AccountingDetailsSection } from "../Components/AccountingDetailsSection"
import { ProofOfPurchaseOrder } from "../orderDetailsTypes"

type DetailedReceiptModalProps = Readonly<{
    order: ProofOfPurchaseOrder
    open: boolean
    onClose: () => void
}>

type GroupedOrderLines = Record<BuyerParty, Record<PaymentMethodType, IOrderLine[]>>

function getLoadedOrder(orderResponse: QueryResult<OrderDetailsResponse>): IMyOrderDetails | undefined {
    return orderResponse.loading || orderResponse.failed ? undefined : orderResponse.response.data.orders[0]
}

function getPaymentDetails(order: ProofOfPurchaseOrder, orderResponse: QueryResult<OrderDetailsResponse>) {
    return getLoadedOrder(orderResponse)?.paymentDetails ?? order.paymentDetails
}

function getKitchen(order: ProofOfPurchaseOrder, orderResponse: QueryResult<OrderDetailsResponse>) {
    return getLoadedOrder(orderResponse)?.kitchen ?? order.kitchen
}

function getCustomer(order: ProofOfPurchaseOrder, orderResponse: QueryResult<OrderDetailsResponse>) {
    return getLoadedOrder(orderResponse)?.organizers?.[0] ?? order.organizers?.[0]
}

function getTimeOfPurchase(order: ProofOfPurchaseOrder, orderResponse: QueryResult<OrderDetailsResponse>, dateTimeFactory: DateTimeFactory) {
    const created = order.created ?? getLoadedOrder(orderResponse)?.created
    return created ? dateTimeFactory.fromFNDateTime(created) : undefined
}

function getDelivery(order: ProofOfPurchaseOrder, orderResponse: QueryResult<OrderDetailsResponse>) {
    return getLoadedOrder(orderResponse)?.deliveries?.[0] ?? order.deliveries?.[0]
}

function getDeliveryComment(orderResponse: QueryResult<OrderDetailsResponse>): Comment | undefined {
    const loadedOrder = getLoadedOrder(orderResponse)
    if (!loadedOrder) return undefined

    const loadedComment = loadedOrder.deliveries?.[0].orderNote
    if (!loadedComment || loadedComment.trim() === "") return { present: false }

    return { present: true, text: loadedComment }
}

function getAccountingDimensions(orderResponse: QueryResult<OrderDetailsResponse>) {
    return getLoadedOrder(orderResponse)?.paymentDetails?.accounting?.dimensions
}

function getOrderLinePaymentMethod(line: IOrderLine, orderPaymentMethod: PaymentMethodType) {
    if (line.paymentDetails?.method) return line.paymentDetails.method
    if (line.buyerParty === BuyerParty.COMPANY) return COMPANY_PAYMENT_METHOD
    return getPaymentMethodType(orderPaymentMethod)
}

function getIncludedVAT(totalPrice: number) {
    const dkVatRate = 0.25
    const vatMultiplier = 1 + dkVatRate
    const priceWithoutVat = totalPrice / vatMultiplier
    return totalPrice - priceWithoutVat
}

function getGroupedOrderLines(orderResponse: QueryResult<OrderDetailsResponse>) {
    const loadedOrder = getLoadedOrder(orderResponse)
    // Todays orders does not contain payment methods for order
    if (!loadedOrder) return undefined

    const orderLines = loadedOrder.deliveries?.[0].orderLines
    if (!orderLines) return undefined

    if (!loadedOrder.paymentDetails?.method) return undefined
    const orderPaymentMethod = loadedOrder.paymentDetails.method

    return orderLines.reduce((acc, line) => {
        if (line.buyerParty) {
            const paymentMethod = getOrderLinePaymentMethod(line, orderPaymentMethod)

            let buyerPartyGroups = acc[line.buyerParty]
            if (!buyerPartyGroups) {
                buyerPartyGroups = {} as Record<PaymentMethodType, IOrderLine[]>
                acc[line.buyerParty] = buyerPartyGroups
            }

            let paymentMethodLines = buyerPartyGroups[paymentMethod]
            if (!paymentMethodLines) {
                paymentMethodLines = []
                buyerPartyGroups[paymentMethod] = paymentMethodLines
            }

            paymentMethodLines.push(line)
        }
        return acc
    }, {} as GroupedOrderLines)
}

function flattenGroupedOrderLines(orderLines: GroupedOrderLines | undefined) {
    if (!orderLines) return undefined

    return Object.entries(orderLines).reduce((allGroups, [buyerParty, group]) => {
        return Object.entries(group).reduce((bpGroups, [paymentMethod, ol]) => {
            const totalPrice = ol.reduce((sum, line) => sum + (line.price?.amount ?? 0), 0)
            const includedVAT = getIncludedVAT(totalPrice)

            const flatGroup: FlatOrderLineGroup = {
                buyerParty: buyerParty as BuyerParty,
                paymentMethod: paymentMethod as PaymentMethodType,
                orderLines: ol,
                totalPrice,
                includedVAT,
            }
            bpGroups.push(flatGroup)
            return bpGroups
        }, allGroups)
    }, [] as FlatOrderLineGroup[])
}

const useStyles = makeStyles((theme) => ({
    scrollShadowBottom: {
        "&::after": {
            zIndex: 1,
            position: "fixed",
            content: '"-"',
            bottom: "-15px",
            left: "0",
            width: "100%",
            boxShadow: "0 -5px 25px rgb(0 0 0 / 18%)"
        }
    },
    edge: {
        height: "10px"
    }
}))

type DetailedReceiptProps = Readonly<{
    order: ProofOfPurchaseOrder
    classes: ReturnType<typeof useStyles>
    setIsAtBottom: (atBottom: boolean) => void
    onClose: () => void
}>

function DetailedReceipt({ order, classes, setIsAtBottom, onClose }: DetailedReceiptProps) {
    const [isForwardReceiptOpen, setForwardReceiptOpen] = useState(false)
    const bottomEdge = createRef<HTMLDivElement>()

    const dateTimeFactory = useDateTime()
    const token = useToken()
    const { currentEnv } = useEnvironment()

    const { response: orderResponse } = useQueryWithOptions(
        () => getOrderDetailsIsomorphic(token, order.id, order.uid, currentEnv),
        {
            dependencies: [order.id, token, currentEnv],
            errorDisplay: ErrorDisplay.Dialog
        }
    )

    const groupedOrderLines = useMemo(
        () => flattenGroupedOrderLines(getGroupedOrderLines(orderResponse)),
        [orderResponse.loading]
    )

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            for (const intersection of entries) {
                if (intersection.target.id === "bottomEdge") {
                    setIsAtBottom(intersection.isIntersecting)
                }
            }
        }, {
            threshold: 1.0
        })

        if (bottomEdge.current) observer.observe(bottomEdge.current)

        return () => observer.disconnect()
    }, [])

    const logger = new Logger("receipt")
    const displayName = order.displayName ?? `${order.id}`

    const topbar = (
        <StandardTopbar
            middleElement={
                <Localized id="receipt-title">
                    <StandardTopbarTitle>Kvittering</StandardTopbarTitle>
                </Localized>
            }
            onClose={onClose}
        />
    )

    const orderUid = getLoadedOrder(orderResponse)?.uid
    const accountingDimensions = getAccountingDimensions(orderResponse)

    return (
        <Screen
            name="orderReceiptDetailed"
            alternativeHeaderElement={topbar}
            containerPadding={0}
            fitPage
        >
            <ReceiptContainer>
                <HeaderSection>{displayName}</HeaderSection>

                <PurchaseDetailsSection
                    paymentDetails={getPaymentDetails(order, orderResponse)}
                    kitchen={getKitchen(order, orderResponse)}
                    customer={getCustomer(order, orderResponse)}
                    timeOfPurchase={getTimeOfPurchase(order, orderResponse, dateTimeFactory)}
                    orderId={`${order.id}`}
                    shopChannel={getLoadedOrder(orderResponse)?.shopChannel}
                />

                <ActionsSection
                    orderId={order.id}
                    orderUid={getLoadedOrder(orderResponse)?.uid}
                    delivery={getDelivery(order, orderResponse)}
                />

                <DeliveryDetailsSection
                    orderType={order.orderType}
                    delivery={getDelivery(order, orderResponse)}
                    deliveryComment={getDeliveryComment(orderResponse)}
                />

                {accountingDimensions && accountingDimensions.length > 0 && (
                    <AccountingDetailsSection dimensions={accountingDimensions} />
                )}

                {groupedOrderLines ? (
                    <>
                        {groupedOrderLines.map((group, index, array) => (

                            <OrderDetailsSection key={index} orderDetails={group} final={index === array.length - 1} />
                        ))}
                    </>
                ) : (
                    <OrderDetailsSection final={true} />
                )}
            </ReceiptContainer>

            {orderUid && (
                <ForwardReceiptDrawer
                    name="detailed-receipt-forward"
                    orderUids={[orderUid]}
                    open={isForwardReceiptOpen}
                    onClose={() => setForwardReceiptOpen(false)}
                />
            )}
            <div ref={bottomEdge} id="bottomEdge" className={classes.edge}></div>
        </Screen>
    )
}

export default function DetailedReceiptModal({ order, open, onClose }: DetailedReceiptModalProps) {
    const classes = useStyles()
    const [isAtBottom, setIsAtBottom] = useState(false)

    return (
        <Dialog open={open} TransitionComponent={SlideUpTransition} fullScreen scroll="paper" PaperProps={{ elevation: 0 }} classes={{ paperScrollPaper: clsx({ [classes.scrollShadowBottom]: !isAtBottom}) }}>
            <ErrorHandlerModal close={onClose}>
                <DetailedReceipt order={order} classes={classes} setIsAtBottom={setIsAtBottom} onClose={onClose} />
            </ErrorHandlerModal>
        </Dialog>
    )
}
