import React from "react"
import { Box, CircularProgress, CircularProgressProps, Grid, makeStyles, Typography } from "@material-ui/core"
import { LocalizedStrict } from "shared/Modules/Localization/Components/Localized"
import { ProgressState } from "../paymentTypes"
import { useDateTime } from "shared/Modules/Localization/useDateTime"

type Props = Readonly<{
    stage: "preparing" | "postprocessing"
    progressState?: ProgressState
}>

const useStyles = makeStyles((theme) => ({
    processingContainer: {
        width: "100%",
        height: "100%",
    },
    processingAnim: {
        maxWidth: theme.breakpoints.values["sm"],
        paddingTop: "3rem",
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
    },
}))

export function ProcessingTransaction({ stage, progressState }: Props) {
    const classes = useStyles()
    const dateTimeFactory = useDateTime()
    const { remaining } = progressState || {}

    return (
        <Grid
            container
            direction="column"
            wrap="nowrap"
            justifyContent="center"
            alignItems="center"
            className={classes.processingContainer}
        >
            <Grid item>
                <LocalizedStrict id="transaction-generic-processing">
                    <Typography variant="h4">
                        {stage === "preparing" ? "Forbereder..." : "Efterbehandler..."}
                    </Typography>
                </LocalizedStrict>
            </Grid>
            {!remaining && (
                <Grid item className={classes.processingAnim}>
                    <CircularProgress variant="indeterminate" />
                </Grid>
            )}
            {remaining && (
                <Grid item className={classes.processingAnim}>
                    <Box position="relative" display="inline-flex">
                        <CircularProgress variant="indeterminate" />
                        <Box
                            top={0}
                            left={0}
                            bottom={0}
                            right={0}
                            position="absolute"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Typography variant="caption" component="div" color="textSecondary">{dateTimeFactory.formatDuration(remaining)}</Typography>
                        </Box>
                    </Box>
                </Grid>
            )}
        </Grid>
    )
}
