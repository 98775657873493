import React from "react"
import { List, ListItem, ListItemSecondaryAction, ListItemText, makeStyles, Typography } from "@material-ui/core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IconProp, library } from "@fortawesome/fontawesome-svg-core"
import { far } from "@fortawesome/pro-regular-svg-icons"
import { faExclamationTriangle } from "@fortawesome/pro-regular-svg-icons/faExclamationTriangle"
import { Localized } from "@fluent/react"
import { Dinero } from "dinero.js"
import { PaymentMethodType } from "shared/Types/appTypes"
import { Logger } from "shared/Helpers/logging"
import { StandardDrawer, StandardDrawerContent } from "shared/Components/Dialog/Drawers"
import { LocalizedStrict } from "shared/Modules/Localization/Components/Localized"
import { MoneyFactory, useMoney } from "shared/Modules/Localization/useMoney"
import { IconDetails, IPaymentMethod } from "../transactionTypes"
import { getKeyForPaymentMethod, getPaymentCardDisplayName, matchPaymentMethod } from "../paymentLib"

// Don't do this at home kids
library.add(far)

type StyleProps = Readonly<{
    sufficientBalance: boolean
}>

const useStyles = makeStyles((theme) => ({
    paymentDrawerHeader: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
    },
    paymentMethods: {
        padding: 0,
        // ListItemSecondaryAction is absolutely positioned 16px from edge of list item, so is not affected by
        // the paddingRight in paymentRow below solve by adding 8px right margin to the list here
        marginRight: theme.spacing(1),
    },
    paymentRow: {
        padding: theme.spacing(2),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
    },
    balance: (props: StyleProps) => ({
        color: props.sufficientBalance ? undefined : undefined, // theme.palette.error.light
    }),
}))

const useImageStyles = makeStyles(() => ({
    paymentMethodIconImg: {
        width: "2rem",
    },
}))

type PaymentMethodIconProps = Readonly<{
    iconDetails: IconDetails | undefined
}>

export function PaymentMethodIcon({ iconDetails }: PaymentMethodIconProps) {
    const logger = new Logger("payment-methods")
    const classes = useImageStyles()

    if (!iconDetails) {
        return null
    } else if (iconDetails.type === "IMAGE_URL") {
        logger.info(`Icon URL: ${iconDetails.url}`)
        return <img className={classes.paymentMethodIconImg} src={iconDetails.url} />
    } else if (iconDetails.type === "FONT_AWESOME" && iconDetails.spec) {
        logger.info(`Font Awesome spec: ${iconDetails.spec}, color: ${iconDetails.color}`)
        // HACK: FontAwesome typings are very strict, so their own examples won't work without a cast
        return <FontAwesomeIcon icon={iconDetails.spec.split(" ") as IconProp} size="xl" color={iconDetails.color} />
    }

    logger.error("Unsupported icon", iconDetails)
    return null
}

type PaymentMethodRowProps = Readonly<{
    method: IPaymentMethod
    selected: boolean
    walletBalance: Dinero<number> | undefined
    sufficientBalance: boolean
    moneyFactory: MoneyFactory
    classes: ReturnType<typeof useStyles>
    onSelectPaymentMethod: (paymentMethod: IPaymentMethod) => void
}>

function PaymentMethodRow({
    method,
    selected,
    walletBalance,
    sufficientBalance,
    moneyFactory,
    classes,
    onSelectPaymentMethod,
}: PaymentMethodRowProps) {
    const isBalanceTooLow = method.value === PaymentMethodType.GOPAY_WALLET && !sufficientBalance
    const isExpired = method.card?.hasExpired
    const isDisabled = isBalanceTooLow || isExpired

    return (
        <ListItem
            button
            divider
            selected={selected}
            disabled={isDisabled}
            className={classes.paymentRow}
            onClick={() => (isDisabled ? false : onSelectPaymentMethod(method))}
        >
            <Localized
                id={method.translationKey}
                vars={{
                    card: getPaymentCardDisplayName(method.card),
                    balance: walletBalance ? moneyFactory.format(walletBalance) : "",
                }}
                elems={{ balance: <span className={classes.balance}></span> }}
            >
                <ListItemText>{method.translationKey}</ListItemText>
            </Localized>
            <ListItemSecondaryAction>
                {isExpired ? (
                    <Typography variant="body2" color="error">
                        <Localized id="payment-method-card-expired">expired</Localized>&nbsp;&nbsp;
                        <FontAwesomeIcon icon={faExclamationTriangle} />
                    </Typography>
                ) : (
                    <PaymentMethodIcon iconDetails={method.icon} />
                )}
            </ListItemSecondaryAction>
        </ListItem>
    )
}

type PaymentMethodDrawerProps = Readonly<{
    paymentMethods: IPaymentMethod[]
    selectedMethod: IPaymentMethod | undefined
    walletBalance: Dinero<number> | undefined
    sufficientBalance: boolean
    open: boolean
    onClose: () => void
    onSelectPaymentMethod: (paymentMethod: IPaymentMethod) => void
}>

export function PaymentMethodDrawer({
    paymentMethods,
    selectedMethod,
    walletBalance,
    sufficientBalance,
    open,
    onClose,
    onSelectPaymentMethod,
}: PaymentMethodDrawerProps) {
    const classes = useStyles({ sufficientBalance })
    const moneyFactory = useMoney()

    return (
        <LocalizedStrict id="order-food-basket-payment-methods" attrs={{ title: true }}>
            <StandardDrawer
                name="payment-methods"
                title="Payment Methods"
                headerVariant="closeable"
                headerClassName={classes.paymentDrawerHeader}
                open={open}
                onClose={onClose}
            >
                <StandardDrawerContent padding={{ left: 0, right: 0 }}>
                    <List className={classes.paymentMethods}>
                        {paymentMethods.map((method) => (
                            <PaymentMethodRow
                                key={getKeyForPaymentMethod(method)}
                                method={method}
                                walletBalance={walletBalance}
                                sufficientBalance={sufficientBalance}
                                selected={matchPaymentMethod(method, selectedMethod)}
                                moneyFactory={moneyFactory}
                                classes={classes}
                                onSelectPaymentMethod={onSelectPaymentMethod}
                            />
                        ))}
                    </List>
                </StandardDrawerContent>
            </StandardDrawer>
        </LocalizedStrict>
    )
}
