import { EffectCallback, useEffect, useRef, useState } from "react"

// Runs an effect and its cleanup once, despite Strict Mode horror show
// See: https://taig.medium.com/prevent-react-from-triggering-useeffect-twice-307a475714d7
// See: https://blog.ag-grid.com/avoiding-react-18-double-mount/
export function useEffectOnce(effect: EffectCallback) {
    const effectFn = useRef(effect)
    const destructorFn = useRef<(() => void) | void>()
    const initialized = useRef(false)
    const rendered = useRef(false)
    const [_val, setVal] = useState(0)

    if (initialized.current) {
        rendered.current = true
    }

    useEffect(() => {
        if (!initialized.current) {
            initialized.current = true
            destructorFn.current = effectFn.current()
        }

        // Force one render after effect has run
        setVal((val) => val + 1)

        return () => {
            if (!rendered.current) {
                // If component didn't render since useEffect was called,
                // we know it's the dummy React cycle
                return
            } else if (destructorFn.current) {
                destructorFn.current()
            }
        }
    }, [])
}
