import { useCallback } from "react"
import { useDispatch } from "react-redux"
import { useLocalization } from "@fluent/react"
import { IMyOrderDetails } from "shared/Types/appTypes"
import { useAppNavigation } from "shared/hooks/useAppNavigation"
import { usePaymentLinks } from "shared/Modules/Cordova/hooks/useAppLinks"
import {
    getFallbackPaymentMethod,
    isOrderResponse,
    isOrdersResponse,
    isPaymentGatewayDetails,
    isPaymentPollingResponse,
    TransactionResponse,
} from "./transactionTypes"
import { openSuccessModal } from "./transactionActions"

type SuccessModalProps = Readonly<{
    title?: string
    message?: string
    meetingName?: string
    orders?: IMyOrderDetails[]
    masterOrder?: IMyOrderDetails
}>

export function useSuccessModal() {
    const dispatch = useDispatch()

    const dispatchSuccess = useCallback(
        ({ title, message, meetingName, orders, masterOrder }: SuccessModalProps) => {
            dispatch(
                openSuccessModal(
                    title ?? "",
                    message ?? "",
                    meetingName ?? "",
                    orders,
                    masterOrder,
                )
            )
        },
        [dispatch, openSuccessModal]
    )

    return dispatchSuccess
}

export function useTransactionHandling() {
    const { l10n } = useLocalization()

    const followPaymentLink = usePaymentLinks()
    const { goToHome, goToPrepareTransaction, goToPaymentPoller } = useAppNavigation()
    const dispatchSuccess = useSuccessModal()

    const transactionResponseHandler = useCallback(
        (response: TransactionResponse) => {
            if (isPaymentPollingResponse(response)) {

                if (isPaymentGatewayDetails(response.paymentDetails)) {
                    // TODO: Remove fallbacks once required info is guaranteed to always be present in payment details
                    const fallbackOrderUid = "abc"
                    const fallbackPaymentMethod = getFallbackPaymentMethod(response)

                    const {
                        paymentWindowUrl,
                        paymentOrderUid = fallbackOrderUid,
                        method = fallbackPaymentMethod,
                    } = response.paymentDetails

                    goToPrepareTransaction()
                    followPaymentLink(paymentWindowUrl, paymentOrderUid, method)

                } else {
                    const { paymentOrderUid, flowDetails } = response.paymentDetails
                    goToPaymentPoller(paymentOrderUid, flowDetails)
                }

            } else if (isOrdersResponse(response)) {
                dispatchSuccess({ orders: response.orders })
                goToHome()

            } else if (isOrderResponse(response)) {
                dispatchSuccess({ orders: [response.order] })
                goToHome()

            } else {
                const defaultMessage = l10n.getString(
                    "transaction-generic-success",
                    undefined,
                    "Transaktion gennemført"
                )
                const { subject = "", message = defaultMessage } = response.transactionResponse

                dispatchSuccess({
                    title: subject,
                    message,
                })
                goToHome()
            }
        },
        [
            isPaymentPollingResponse,
            isPaymentGatewayDetails,
            getFallbackPaymentMethod,
            followPaymentLink,
            goToPaymentPoller,
            isOrdersResponse,
            isOrderResponse,
            dispatchSuccess,
            goToHome,
            l10n,
        ]
    )

    return {
        handleTransactionResponse: transactionResponseHandler,
    }
}
