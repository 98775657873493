import { DeferredQueryResult } from "shared/Modules/Query/queryTypes"
import { UserSurveyQuestions } from "shared/Modules/Surveys/surveyTypes"
import { UserCardsResponse } from "./userCardsApi"

export enum UserCards {
    SUMMARY_NUMBER = "SUMMARY_NUMBER",
    EXTERNAL_LINK = "EXTERNAL_LINK",
    LUNCH_SUBSCRIPTION = "LUNCH_SUBSCRIPTION",
    USER_SURVEY = "USER_SURVEY",
    NEWS_ITEM = "NEWS_ITEM",
    CHECK_IN_REMINDER = "CHECK_IN_REMINDER",
}
export type UserCardType = keyof typeof UserCards

export interface IBaseUserCard {
    id: string
}

/**
 * Summary Number Card Types
 */
export interface ISummaryNumberCardColors {
    header?: string
    value?: string
    background?: string
    unit?: string
    footer?: string
}

export interface ISummaryNumberDetails {
    content: {
        header?: string
        value: string
        unit?: string
        footer?: string
    }
    colors?: ISummaryNumberCardColors
}

export interface ISummaryUserCard extends IBaseUserCard {
    type: UserCards.SUMMARY_NUMBER
    summaryNumberDetails: ISummaryNumberDetails
}

/**
 * Lunch Subscription Card Types
 */
export interface ILunchSubscriptionCardColors {
    displayName?: string
    statusLabel?: string
    statusLabelBackground?: string
    merchantDisplayName?: string
    subscriptionDateLabel?: string
    background?: string
}

export interface ILunchSubscriptionDetails {
    content: {
        displayName: string
        statusLabel?: string
        merchantDisplayName?: string
        subscriptionDateLabel?: string
        subscriptionDate?: string
    }
    colors?: ILunchSubscriptionCardColors
}

export interface ILunchSubscriptionUserCard extends IBaseUserCard {
    type: UserCards.LUNCH_SUBSCRIPTION
    lunchSubscriptionDetails: ILunchSubscriptionDetails
}

/**
 * External Link Card Types
 */
export interface IExternalLinkDetails {
    content: {
        headline: string
        description: string
        url: string
        image?: string
    }
}
export interface IExternalLinkCard extends IBaseUserCard {
    type: UserCards.EXTERNAL_LINK
    externalLinkDetails: IExternalLinkDetails
}

/**
 * User Survey Card Types
 */
export interface IUserSurveyCardColors {
    header?: string
    basketIcon?: string
    productName?: string
    kitchenName?: string
    closeIcon?: string
    action?: string
    background?: string
}

export interface IUserSurveyDetails {
    content: {
        surveyId: number
        orderId: number
        header: string
        kitchen: {
            name: string
        }
        product: {
            id: number
            name: string
            imageUrl: string
        }
        questions: UserSurveyQuestions
    }
    colors?: IUserSurveyCardColors
}
export interface IUserSurveyCard extends IBaseUserCard {
    type: UserCards.USER_SURVEY
    surveyDetails: IUserSurveyDetails
}

export interface IUserNewsDetails {
    content: {
        imageUrl: string
        subject: string
        permaLink: string
        external: boolean
    }
}
export interface IUserNewsCard extends IBaseUserCard {
    type: UserCards.NEWS_ITEM
    newsItemDetails: IUserNewsDetails
}

export interface ICheckInReminderDetails {
    content: {
        isCheckedIn: boolean
    }
}
export interface ICheckInReminder extends IBaseUserCard {
    type: UserCards.CHECK_IN_REMINDER
    checkInReminderDetails: ICheckInReminderDetails
}

export type UserCard =
    | ISummaryUserCard
    | ILunchSubscriptionUserCard
    | IExternalLinkCard
    | IUserSurveyCard
    | IUserNewsCard
    | ICheckInReminder

export interface IUserCardGroup {
    readonly cards: UserCard[]
}

export interface IUserCardsSection {
    readonly id: string
    readonly title: string
    readonly groups: IUserCardGroup[]
}

export enum UserCardPages {
    home = "home",
    me = "me",
}
export type UserCardPagesType = keyof typeof UserCardPages

type UserCardsStateByPage = {
    [key in UserCardPages]: DeferredQueryResult<UserCardsResponse>
}
type UserCardsStateHidden = {
    hiddenCardIds: string[]
}

export type UserCardsState = UserCardsStateByPage & UserCardsStateHidden
