import { DateTime, Interval, Duration } from "luxon"
import { useLocalization } from "@fluent/react"
import { IBookingSuggestion, ICheckInDetails, IMeetingDetails, IOpeningTime, ITimeSlot } from "shared/Types/appTypes"
import { ISO_TIME_MINUTE_FORMAT } from "shared/Helpers/DateTimeHelper"
import { ILunchSubscriptionPeriods } from "shared/Modules/Subscriptions/subscriptionTypes"
import { useLocale } from "./useLocale"

const FN_DATETIME_FORMAT = "yyyy-MM-dd HH:mm"

function capitalize(text: string) {
    return text.charAt(0).toUpperCase() + text.slice(1)
}

interface DateTimeFactoryArguments {
    locale: string
    dateFormat: string
    dayAndDateFormat: string
    monthYearFormat: string
    timeFormat: string
    dateTimeFormat: string
    durationHourMinuteFormat: string
    durationMinuteFormat: string
    durationSecondsFormat: string
}

export class DateTimeFactory {
    private locale: string
    private dateFormat: string
    private dayAndDateFormat: string
    private monthYearFormat: string
    private timeFormat: string
    private dateTimeFormat: string
    private durationHourMinuteFormat: string
    private durationMinuteFormat: string
    private durationSecondsFormat: string

    constructor({
        locale,
        dateFormat,
        dayAndDateFormat,
        monthYearFormat,
        timeFormat,
        dateTimeFormat,
        durationHourMinuteFormat,
        durationMinuteFormat,
        durationSecondsFormat,
    }: DateTimeFactoryArguments) {
        this.locale = locale
        this.dateFormat = dateFormat
        this.dayAndDateFormat = dayAndDateFormat
        this.monthYearFormat = monthYearFormat
        this.timeFormat = timeFormat
        this.dateTimeFormat = dateTimeFormat
        this.durationHourMinuteFormat = durationHourMinuteFormat
        this.durationMinuteFormat = durationMinuteFormat
        this.durationSecondsFormat = durationSecondsFormat
    }

    now() {
        return DateTime.local().setLocale(this.locale)
    }

    fromFNDateTime(dateTime: string) {
        return DateTime.fromSQL(dateTime, { locale: this.locale })
    }

    fromISO(dateTime: string) {
        return DateTime.fromISO(dateTime, { locale: this.locale })
    }

    fromHourMinute(time: string) {
        return DateTime.fromFormat(time, ISO_TIME_MINUTE_FORMAT, { locale: this.locale })
    }

    getBookingSuggestionInterval(bookingSuggestion: IBookingSuggestion) {
        const bookingStart = this.fromFNDateTime(bookingSuggestion.startDatetime)
        const bookingEnd = this.fromFNDateTime(bookingSuggestion.endDatetime)
        return Interval.fromDateTimes(bookingStart, bookingEnd)
    }

    getTimeslotInterval(timeslot: ITimeSlot) {
        const timeslotStart = this.fromFNDateTime(timeslot.startDatetime)
        const timeslotEnd = this.fromFNDateTime(timeslot.endDatetime)
        return Interval.fromDateTimes(timeslotStart, timeslotEnd)
    }

    getMeetingInterval(meeting: IMeetingDetails) {
        const meetingStart = this.fromFNDateTime(meeting.startDateTime)
        const meetingEnd = this.fromFNDateTime(meeting.endDateTime)
        return Interval.fromDateTimes(meetingStart, meetingEnd)
    }

    getCheckinInterval(meeting: IMeetingDetails, checkinDetails: ICheckInDetails) {
        const meetingStart = this.fromFNDateTime(meeting.startDateTime)
        const checkinFrom = meetingStart.minus({ minutes: checkinDetails.checkInMinutesBeforeMeeting })
        const checkinTo = meetingStart.plus({ minutes: checkinDetails.checkInMinutesBeforeMeeting })
        return Interval.fromDateTimes(checkinFrom, checkinTo)
    }

    fromOpeningTime(isoDate: string, openingTime: IOpeningTime) {
        const open = DateTime.fromISO(`${isoDate}T${openingTime.openingTime}`, { locale: this.locale })
        const close = DateTime.fromISO(`${isoDate}T${openingTime.closingTime}`, { locale: this.locale })
        return Interval.fromDateTimes(open, close)
    }

    fromSubscriptionPeriod(period: ILunchSubscriptionPeriods) {
        if (period.validFrom) {
            return DateTime.fromISO(period.validFrom, { locale: this.locale })
        } else {
            return DateTime.fromObject({ day: 1, month: period.month, year: period.year, locale: this.locale })
        }
    }

    toFNDateTime(dateTime: DateTime) {
        return dateTime.toFormat(FN_DATETIME_FORMAT)
    }

    formatDate(dateTime: DateTime) {
        return dateTime.toFormat(this.dateFormat)
    }

    formatDayAndDate(dateTime: DateTime) {
        return capitalize(dateTime.toFormat(this.dayAndDateFormat))
    }

    formatMonthYear(dateTime: DateTime) {
        return dateTime.toFormat(this.monthYearFormat)
    }

    formatTime(dateTime: DateTime) {
        return dateTime.toFormat(this.timeFormat)
    }

    formatDateTime(dateTime: DateTime) {
        return dateTime.toFormat(this.dateTimeFormat)
    }

    formatDuration(duration: Duration) {
        if (duration.hours > 0) {
            return duration.toFormat(this.durationHourMinuteFormat)
        } else if (duration.minutes > 0) {
            return duration.toFormat(this.durationMinuteFormat)
        } else {
            return duration.toFormat(this.durationSecondsFormat)
        }
    }

    formatPeriod(start: DateTime, end: DateTime) {
        if (start.hasSame(end, "day")) {
            return `${this.formatDate(start)} ${this.formatTime(start)} - ${this.formatTime(end)}`
        } else {
            return `${this.formatDateTime(start)} - ${this.formatDateTime(end)}`
        }
    }

    formatInterval(interval: Interval) {
        return this.formatPeriod(interval.start, interval.end)
    }

    formatPeriodDuration(start: DateTime, dur: Duration) {
        const end = start.plus(dur)
        return this.formatPeriod(start, end)
    }

    formatMeetingPeriod(meeting: IMeetingDetails) {
        const meetingInterval = this.getMeetingInterval(meeting)
        return this.formatInterval(meetingInterval)
    }

    formatBookingSuggestion(suggestion: IBookingSuggestion) {
        const interval = this.getBookingSuggestionInterval(suggestion)
        return this.formatInterval(interval)
    }
}

export function useDateTime() {
    const { l10n } = useLocalization()
    const locale = useLocale()

    return new DateTimeFactory({
        locale,
        dateFormat: l10n.getString("date-time-format-date"),
        dayAndDateFormat: l10n.getString("date-time-format-day-and-date"),
        monthYearFormat: l10n.getString("date-time-format-month-and-year"),
        timeFormat: l10n.getString("date-time-format-time"),
        dateTimeFormat: l10n.getString("date-time-format-date-and-time"),
        durationHourMinuteFormat: l10n.getString("date-time-format-duration-hours-minutes"),
        durationMinuteFormat: l10n.getString("date-time-format-duration-minutes"),
        durationSecondsFormat: l10n.getString("date-time-format-duration-seconds"),
    })
}
