import { DateTime } from "luxon"
import { ApiErrorResponse, ApiResponse, ApiResponseTypes, IErrorResponse, IProtocolError, ISuccessResponse } from "shared/Types/responseTypes"

export type QueryLoadingResult = Readonly<{
    loading: true
}>

export type QueryReadyResult<T> = Readonly<{
    loading: false
    processed: false
    response: ApiResponse<T>
}>

export type QueryCompleteResult<T> = Readonly<{
    loading: false
    processed: true
    failed: false
    response: ISuccessResponse<T>
}>

export type OptionalQueryCompleteResult<T> = Readonly<{
    loading: false
    processed: true
    failed: false
    response?: ISuccessResponse<T>
}>

export interface QueryErrorResult {
    loading: false
    processed: true
    failed: true
    message: string
}

export type QueryResult<T> = QueryLoadingResult | QueryCompleteResult<T> | QueryErrorResult
export type OptionalQueryResult<T> = QueryLoadingResult | OptionalQueryCompleteResult<T> | QueryErrorResult
export type DeferredQueryResult<T> = QueryLoadingResult | QueryReadyResult<T>

export type QueryLogItem = Readonly<{
    timestamp: DateTime
    response: ApiResponse<unknown>
}>

export type QueryState = Readonly<{
    recentQueries: QueryLogItem[]
}>

export type QueryStore = Readonly<{
    queries: QueryState
}>

export class QueryError extends Error {
    private readonly _response: ApiErrorResponse

    constructor(response: ApiErrorResponse, message: string) {
        super(message)

        // Prettier logging
        Object.defineProperty(this, 'name', {
            value: new.target.name,
            enumerable: false,
            configurable: true,
        })

        this._response = response

        // Fix up prototype chain
        // See: https://www.typescriptlang.org/docs/handbook/release-notes/typescript-2-2.html#support-for-newtarget
        Object.setPrototypeOf(this, new.target.prototype)
    }

    public get responseType() : ApiResponseTypes {
        return this._response.type
    }

    public get responseIfApiError() : IErrorResponse | undefined {
        return this._response.type === ApiResponseTypes.API_ERROR ? this._response : undefined
    }

    public get responseIfProtocolError() : IProtocolError | undefined {
        return this._response.type === ApiResponseTypes.PROTOCOL_ERROR ? this._response : undefined
    }

    public get httpStatusCodeIfPresent() : number | undefined {
        return this._response.type === ApiResponseTypes.NETWORK_ERROR ? undefined : this._response.responseCode
    }
}
