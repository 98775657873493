import { IMonetaryAmount, IMyOrderDetails, IPurchaseCard, IUser, PaymentMethodType } from "shared/Types/appTypes"
import { objectHas } from "shared/Helpers/lang"
import { FormInputField, InputType, ValidationRule } from "../Forms/formTypes"
import { ApiResponse } from "shared/Types/responseTypes"

type ImageIconDetails = Readonly<{
    type: "IMAGE_URL"
    url: string
}>
type FontAwesomeIconDetails = Readonly<{
    type: "FONT_AWESOME"
    spec?: string
    color?: string
}>
export type IconDetails = ImageIconDetails | FontAwesomeIconDetails

export type AccountingDimension = Readonly<{
    id: number
    inputField: FormInputField
}>

export type AccountingDetails = Readonly<{
    dimensions?: AccountingDimension[]
}>

export interface BasePaymentMethod {
    readonly value: PaymentMethodType
    readonly card?: IPurchaseCard
    readonly icon?: IconDetails
}

export interface IPaymentMethod extends BasePaymentMethod {
    readonly translationKey: string
    readonly showSalesConditions: boolean
    readonly oneClickPaymentId?: number
    readonly accounting?: AccountingDetails
    readonly notice?: string
}

interface IDeliveryDetails {
    fillGuestDetails: boolean
}

export interface WebshopPaymentDetails {
    salesConditionsUrl: string
    currencyCodes: string[]
    paymentMethods: IPaymentMethod[]
    userAccountBalance?: IMonetaryAmount
    deliveryDetails?: IDeliveryDetails
}

export interface ITransactionResponse {
    readonly message: string
    readonly subject: string
}

export type FlowDetails = Readonly<{
    type: string
    source: string
}>

interface BasePaymentDetails {
    paymentId: string
    paymentOrderId: string
    paymentOrderUid?: string
    orderId: number
    method?: PaymentMethodType
}

interface PaymentGatewayDetails extends BasePaymentDetails {
    paymentWindowUrl: string
}

interface PaymentFlowDetails extends BasePaymentDetails {
    paymentOrderUid: string
    flowDetails: FlowDetails
}

type PaymentDetails = PaymentGatewayDetails | PaymentFlowDetails

type SimpleTransactionResponse = Readonly<{
    transactionResponse: ITransactionResponse
}>

type PaymentPollingResponse = Readonly<{
    paymentDetails: PaymentGatewayDetails | PaymentFlowDetails
}>

type OrderResponse = Readonly<{
    order: IMyOrderDetails
}>

type OrdersResponse = Readonly<{
    orders: IMyOrderDetails[]
}>

export type TransactionResponse = SimpleTransactionResponse | PaymentPollingResponse | OrderResponse | OrdersResponse

export type SuccessModalState = Readonly<{
    open: boolean
    title: string
    message: string
    caption: string
    orders?: IMyOrderDetails[]
    masterOrder?: IMyOrderDetails
}>

export type TransactionState = Readonly<{
    loading: boolean
    successModal: SuccessModalState
}>

export type TransactionStore = Readonly<{
    transactionState: TransactionState
}>

export function isPaymentGatewayDetails(paymentDetails: PaymentDetails): paymentDetails is PaymentGatewayDetails {
    if (objectHas("paymentWindowUrl", paymentDetails)) return true
    return false
}

export function isPaymentFlowDetails(paymentDetails: PaymentDetails): paymentDetails is PaymentFlowDetails {
    if (objectHas("flowDetails", paymentDetails)) {
        if (paymentDetails.paymentOrderUid) return true
    }

    return false
}

export function isPaymentPollingResponse(response: TransactionResponse): response is PaymentPollingResponse {
    if (objectHas("paymentDetails", response)) {
        return isPaymentGatewayDetails(response.paymentDetails) || isPaymentFlowDetails(response.paymentDetails)
    }

    return false
}

export function isOrdersResponse(response: TransactionResponse): response is OrdersResponse {
    if (objectHas("orders", response)) {
        return response.orders.length > 0
    }

    return false
}

export function isOrderResponse(response: TransactionResponse): response is OrderResponse {
    if (objectHas("order", response)) return true
    return false
}

export function getFallbackPaymentMethod(response: TransactionResponse) {
    if (isOrdersResponse(response)) return response.orders[0].payment?.method
    if (isOrderResponse(response)) return response.order.payment?.method

    return undefined
}
