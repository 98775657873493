import { IMyOrderDetails } from "shared/Types/appTypes"

export enum TransactionActionTypes {
    BEGIN_TRANSACTION = "BEGIN_TRANSACTION",
    END_TRANSACTION = "END_TRANSACTION",
    OPEN_SUCCESS_MODAL = "OPEN_SUCCESS_MODAL",
    CLOSE_SUCCESS_MODAL = "CLOSE_SUCCESS_MODAL",
    OPEN_ACTION_FAILED_MODAL = "OPEN_ACTION_FAILED_MODAL",
    CLOSE_ACTION_FAILED_MODAL = "CLOSE_ACTION_FAILED_MODAL",
}

export interface IBeginTransaction {
    readonly type: TransactionActionTypes.BEGIN_TRANSACTION
}

export interface IEndTransaction {
    readonly type: TransactionActionTypes.END_TRANSACTION
}

export interface IOpenSuccessModal {
    readonly type: TransactionActionTypes.OPEN_SUCCESS_MODAL
    readonly title: string
    readonly message: string
    readonly caption: string
    readonly orders?: IMyOrderDetails[]
    readonly masterOrder?: IMyOrderDetails
}

export interface ICloseSuccessModal {
    readonly type: TransactionActionTypes.CLOSE_SUCCESS_MODAL
}

export interface IOpenActionFailedModal {
    readonly type: TransactionActionTypes.OPEN_ACTION_FAILED_MODAL
    readonly message: string
    readonly subtitle: string
    readonly id: number | null
    readonly meetingName: string
}

export interface ICloseActionFailedModal {
    readonly type: TransactionActionTypes.CLOSE_ACTION_FAILED_MODAL
}

export const beginTransaction = (): IBeginTransaction => ({
    type: TransactionActionTypes.BEGIN_TRANSACTION,
})

export const endTransaction = (): IEndTransaction => ({
    type: TransactionActionTypes.END_TRANSACTION,
})

export const openSuccessModal = (title: string, message: string, caption: string, orders?: IMyOrderDetails[], masterOrder?: IMyOrderDetails): IOpenSuccessModal => ({
    type: TransactionActionTypes.OPEN_SUCCESS_MODAL,
    title,
    message,
    caption,
    orders,
    masterOrder,
})

export const closeSuccessModal = (): ICloseSuccessModal => ({
    type: TransactionActionTypes.CLOSE_SUCCESS_MODAL,
})

export const openActionFailedModal = (message: string, subtitle: string, id: number | null, meetingName: string): IOpenActionFailedModal => ({
    type: TransactionActionTypes.OPEN_ACTION_FAILED_MODAL,
    message,
    subtitle,
    id,
    meetingName,
})

export const closeActionFailedModal = (): ICloseActionFailedModal => ({
    type: TransactionActionTypes.CLOSE_ACTION_FAILED_MODAL,
})

export type TransactionAction =
    | IBeginTransaction
    | IEndTransaction
    | IOpenSuccessModal
    | ICloseSuccessModal
    | IOpenActionFailedModal
    | ICloseActionFailedModal
