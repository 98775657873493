import React, { useCallback, useState } from "react"
import CardCarousel from "mobile/Components/CardCarousel"
import Card from "shared/Components/Card/Card"
import { createStyles, makeStyles } from "@material-ui/core/styles"
import { Section } from "shared/Components/Skeleton/Section"
import {
    IExternalLinkDetails,
    ILunchSubscriptionDetails,
    UserCard,
    IUserCardGroup,
    ISummaryNumberDetails,
    IUserCardsSection,
    ISummaryNumberCardColors,
    ILunchSubscriptionCardColors,
    UserCards,
    IUserSurveyCardColors,
    IUserNewsDetails,
    IUserSurveyCard,
    IUserSurveyDetails,
    ICheckInReminderDetails,
} from "shared/Modules/UserCards/userCardsTypes"
import { CardMedia, Grid, IconButton, Typography } from "@material-ui/core"
import { useExternalLinks } from "shared/Modules/Cordova/hooks/useAppLinks"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faShoppingBasket, faArrowRight, faPartyHorn, faCircleExclamation } from "@fortawesome/pro-solid-svg-icons"
import { faTimes } from "@fortawesome/pro-light-svg-icons"
import AssetHelper from "shared/Helpers/AssetHelper"
import SurveyCardBackground from "shared/Modules/Surveys/Assets/smiley_survey_bg.svg"
import { UserSurveyModal } from "shared/Modules/Surveys/Screens/UserSurveyModal"
import { Localized } from "@fluent/react"
import clsx from "clsx"
import { useDispatch } from "react-redux"
import { hideUserCard } from "../userCardsActions"
import { useUserSurveyModal } from "shared/Modules/Surveys/userSurveyHooks"

interface IClassesProps {
    summaryNumber?: ISummaryNumberCardColors
    lunchSubscription?: ILunchSubscriptionCardColors
    userSurvey?: IUserSurveyCardColors
    checkInReminder?: boolean
}

const useStyles = makeStyles((theme) =>
    createStyles({
        summaryCardContent: ({ summaryNumber }: IClassesProps) => ({
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: summaryNumber?.background || "#FFFFFFFF",
        }),
        summaryCardTextRowWrapper: {
            height: "2.75rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
        summaryCardHeader: ({ summaryNumber }: IClassesProps) => ({
            fontSize: "0.75rem",
            fontWeight: 600,
            color: summaryNumber?.header || "#000000FF",
            verticalAlign: "middle",
        }),

        summaryCardValue: ({ summaryNumber }: IClassesProps) => ({
            fontSize: "3.5rem",
            margin: "4px 0",
            color: summaryNumber?.value || "#000000FF",
        }),
        summaryCardUnit: ({ summaryNumber }: IClassesProps) => ({
            fontSize: "1rem",
            color: summaryNumber?.unit || summaryNumber?.value || "#000000FF",
            marginLeft: "0.25rem",
        }),
        summaryCardFooter: ({ summaryNumber }: IClassesProps) => ({
            fontSize: "0.75rem",
            color: summaryNumber?.footer || "#969696FF",
        }),
        cardCarousel: {
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
        },
        lunchSubscriptionCardContent: ({ lunchSubscription }: IClassesProps) => ({
            backgroundColor: lunchSubscription?.background || "#FFFFFFFF",
            padding: "1rem",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
        }),
        lunchSubscriptionHeaderWrapper: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "start",
        },
        lunchSubscriptionHeadline: ({ lunchSubscription }: IClassesProps) => ({
            color: lunchSubscription?.displayName || theme.palette.text.primary || "#FFFFFFFF",
            fontSize: "1.125rem",
            fontWeight: 700,
            margin: 0,
            marginRight: "1rem",
        }),
        statusLabel: ({ lunchSubscription }: IClassesProps) => ({
            backgroundColor: lunchSubscription?.statusLabelBackground || theme.palette.common.white || "#969696FF",
            color: lunchSubscription?.statusLabel || theme.palette.common.black || "#969696FF",
            borderRadius: "0.25rem",
            padding: "0.25rem 0.5rem",
            fontSize: "0.75rem",
        }),

        lunchSubscriptionMerchantName: ({ lunchSubscription }: IClassesProps) => ({
            color: lunchSubscription?.merchantDisplayName || theme.palette.text.secondary || "#969696FF",
            fontSize: "0.875rem",
            marginTop: "0.5rem",
        }),
        lunchSubscriptionDateWrapper: {
            fontSize: "0.75rem",
        },
        lunchSubscriptionDateLabel: ({ lunchSubscription }: IClassesProps) => ({
            color: lunchSubscription?.subscriptionDateLabel || theme.palette.text.secondary || "#969696FF",
        }),
        relative: {
            position: "relative",
        },
        pointer: {
            cursor: "pointer",
        },
        externalLinkCardContent: {
            paddingTop: "1rem",
            paddingLeft: "1rem",
        },
        userSurveyCardContent: ({ userSurvey }: IClassesProps) => ({
            backgroundColor: userSurvey?.background || theme.palette.common.white,
            padding: "1rem",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
        }),
        userSurveyCardTitle: ({ userSurvey }: IClassesProps) => ({
            color: userSurvey?.header || theme.palette.primary.main,
            fontSize: "1.125rem",
            fontWeight: 700,
        }),
        userSurveyCloseButton: ({ userSurvey }: IClassesProps) => ({
            color: userSurvey?.closeIcon || theme.palette.primary.main,
            height: theme.spacing(2.5),
            width: theme.spacing(2.5),
        }),
        userSurveyBasketIcon: ({ userSurvey }: IClassesProps) => ({
            color: userSurvey?.basketIcon || theme.palette.text.secondary,
            marginRight: theme.spacing(1),
        }),
        userSurveyProductName: ({ userSurvey }: IClassesProps) => ({
            color: userSurvey?.productName || theme.palette.text.primary,
            height: "4em",
            fontWeight: 400,
            lineHeight: 1.3,
            display: "-webkit-box",
            overflow: "hidden",
            textOverflow: "ellipsis",
            WebkitLineClamp: 3,
            WebkitBoxOrient: "vertical",
        }),
        userSurveyKitchenName: ({ userSurvey }: IClassesProps) => ({
            color: userSurvey?.kitchenName || theme.palette.text.secondary,
            marginTop: "0.5rem",
        }),
        userSurveyRow: {
            display: "flex",
            justifyContent: "space-between",
            padding: 0,
        },
        userSurveyCardBg: {
            backgroundImage: `url(${AssetHelper.url(SurveyCardBackground)})`,
            backgroundPosition: `center right`,
            backgroundRepeat: "no-repeat",
            backgroundOrigin: "content-box",
            backgroundSize: 100,
            backgroundBlendMode: "color-burn",
        },
        userSurveyActionsRow: {
            display: "flex",
            justifyContent: "flex-end",
        },
        userSurveyProductBlock: {
            flex: "1 1 auto",
            margin: "0.5rem 0",
        },
        userSurbeyRateButtonBlock: {
            flex: "0 0 auto",
            alignSelf: "flex-end",
            width: "100px",
        },
        userSurveyRateButton: ({ userSurvey }: IClassesProps) => ({
            color: userSurvey?.action || theme.palette.primary.main,
            filter: "opacity(0.8)",
        }),
        cardHeading: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
        },
        newsCard: {
            "& > .MuiPaper-root": {
                boxShadow: "none",
            },
        },
        newsCardTitle: {
            fontSize: theme.typography.body1.fontSize,
            margin: theme.spacing(1.2, 0, 0),
        },
        newsCardMedia: {
            width: "100%",
            aspectRatio: "16/9",
        },
        checkInReminderCardContent: ({ checkInReminder }: IClassesProps) => ({
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "0.625rem",
            padding: "1rem",
            background: checkInReminder ? "#86EFAC" : "#FCA5A5",
            color: checkInReminder ? "#14532D" : "#7F1D1D",
        }),
        checkInReminderCardContentBox: {
            gap: "0.625rem",
            justifyContent: "flex-end",
            alignItems: "center",
        },
    })
)

/**
 * Lunch Subscription Card
 */
type LunchSubscriptionCardProps = ILunchSubscriptionDetails

function LunchSubscriptionCard(props: LunchSubscriptionCardProps) {
    const { displayName, statusLabel, merchantDisplayName, subscriptionDate, subscriptionDateLabel } = props.content
    const classes = useStyles({
        lunchSubscription: props.colors ?? {},
    })

    return (
        <Card height="120px" width="100%">
            <div className={classes.lunchSubscriptionCardContent}>
                <div>
                    <div className={classes.lunchSubscriptionHeaderWrapper}>
                        <Typography variant="h4" className={classes.lunchSubscriptionHeadline}>
                            {displayName}
                        </Typography>
                        <div className={classes.statusLabel}>{statusLabel}</div>
                    </div>
                    <Typography variant="body2" className={classes.lunchSubscriptionMerchantName}>
                        {merchantDisplayName}
                    </Typography>
                </div>

                <div className={classes.lunchSubscriptionDateWrapper}>
                    <span className={classes.lunchSubscriptionDateLabel}>{subscriptionDateLabel}</span>{" "}
                    {subscriptionDate}
                </div>
            </div>
        </Card>
    )
}

// Check-in reminder Card
function CheckInReminderCard(props: ICheckInReminderDetails) {
    const { isCheckedIn } = props.content
    const classes = useStyles({
        checkInReminder: isCheckedIn,
    })

    return (
        <Card width="100%" className={classes.pointer} onClick={() => {}}>
            <Grid container className={classes.checkInReminderCardContent}>
                <FontAwesomeIcon size="3x" icon={isCheckedIn ? faPartyHorn : faCircleExclamation} />
                <Localized
                    id={
                        isCheckedIn
                            ? "home-user-card-check-in-reminder-check-in"
                            : "home-user-card-check-in-reminder-no-check-in"
                    }
                >
                    <Typography variant="h4">
                        {isCheckedIn
                            ? "Please remember to check into the canteen today"
                            : "Please remember to check into the canteen today"}{" "}
                    </Typography>
                </Localized>
                <Grid container className={classes.checkInReminderCardContentBox}>
                    <Localized id="home-user-card-check-in-reminder-learn-more">
                        <Typography variant="body2">Learn more</Typography>
                    </Localized>
                    <FontAwesomeIcon icon={faArrowRight} />
                </Grid>
            </Grid>
        </Card>
    )
}

/**
 * Summary Number Card
 */
type SummaryNumberCardProps = ISummaryNumberDetails
function SummaryNumberCard(props: SummaryNumberCardProps) {
    const { header, value, unit, footer } = props.content
    const classes = useStyles({
        summaryNumber: props.colors ?? {},
    })

    return (
        <Card width="100%">
            <div className={classes.summaryCardContent}>
                <div className={classes.summaryCardTextRowWrapper}>
                    <Typography variant="body1" className={classes.summaryCardHeader}>
                        {header}
                    </Typography>
                </div>
                <Typography variant="body2" className={classes.summaryCardValue}>
                    {value}
                    {unit && <span className={classes.summaryCardUnit}>{unit}</span>}
                </Typography>
                <div className={classes.summaryCardTextRowWrapper}>
                    <Typography variant="body1" className={classes.summaryCardFooter}>
                        {footer}
                    </Typography>
                </div>
            </div>
        </Card>
    )
}

/**
 * External Link Card
 */
type ExternalLinkCardProps = IExternalLinkDetails
function ExternalLinkCard(props: ExternalLinkCardProps) {
    const { url, headline, image, description } = props.content
    const classes = useStyles({})

    const openExternalLink = useExternalLinks()
    const handleExternalLinkClick = (url: string) => {
        if (url) {
            openExternalLink(url)
        }
    }

    return (
        <Card
            onClick={() => {
                handleExternalLinkClick(url ?? "")
            }}
            width="100%"
            className={classes.pointer}
        >
            {image && (
                <div className={classes.relative}>
                    <CardMedia
                        component="img"
                        alt={headline}
                        height="172"
                        style={{ borderRadius: 5 }}
                        image={image}
                        title={headline}
                    />
                </div>
            )}
            <div className={classes.externalLinkCardContent}>
                <Typography variant="h4" className={classes.lunchSubscriptionHeadline}>
                    {headline}
                </Typography>
                <Typography variant="body2" className={classes.lunchSubscriptionMerchantName}>
                    {description}
                </Typography>
            </div>
        </Card>
    )
}

type UserSurveyCardProps = Readonly<{
    card: IUserSurveyCard
    showSurveyModal: (survey: IUserSurveyDetails["content"]) => void
}>

/**
 * User Survey Card
 */
function UserSurveyCard({ card: { id, surveyDetails }, showSurveyModal }: UserSurveyCardProps) {
    const { content, colors } = surveyDetails
    const { header, product, kitchen } = content
    const dispatch = useDispatch()

    const classes = useStyles({
        userSurvey: colors ?? {},
    })

    function handleSurveyDismiss(event: React.MouseEvent<HTMLButtonElement>) {
        event.stopPropagation()
        dispatch(hideUserCard(id))
    }

    const handleSurveyOpen = useCallback(() => showSurveyModal(content), [showSurveyModal, content])

    return (
        <>
            <Card width="100%" onClick={handleSurveyOpen}>
                <div className={clsx(classes.userSurveyCardContent, classes.userSurveyCardBg)}>
                    <div className={classes.cardHeading}>
                        <Typography variant="h4" className={classes.userSurveyCardTitle}>
                            {header}
                        </Typography>
                        <IconButton
                            aria-label="close"
                            size="small"
                            className={classes.userSurveyCloseButton}
                            onClick={handleSurveyDismiss}
                        >
                            <FontAwesomeIcon icon={faTimes} />
                        </IconButton>
                    </div>
                    <Typography variant="body2" className={classes.userSurveyKitchenName}>
                        {kitchen.name}
                    </Typography>

                    <Grid container wrap="nowrap" justifyContent="space-between" alignItems="flex-start">
                        <Grid
                            item
                            container
                            wrap="nowrap"
                            alignItems="baseline"
                            className={classes.userSurveyProductBlock}
                        >
                            <Grid item>
                                <FontAwesomeIcon className={classes.userSurveyBasketIcon} icon={faShoppingBasket} />
                            </Grid>
                            <Grid item>
                                <Typography variant="body1" className={classes.userSurveyProductName}>
                                    {product.name}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item className={classes.userSurbeyRateButtonBlock}>
                            <Localized id="home-user-card-survey-rate-now">
                                <Typography align="right" className={classes.userSurveyRateButton} variant="body2">
                                    Rate now
                                </Typography>
                            </Localized>
                        </Grid>
                    </Grid>
                </div>
            </Card>
        </>
    )
}

/**
 * User News Card
 */
type UserNewsCardProps = IUserNewsDetails
function UserNewsCard(props: UserNewsCardProps) {
    const { content } = props
    const { imageUrl, subject, permaLink } = content
    const classes = useStyles({})

    const openExternalLink = useExternalLinks()
    const handleLinkClick = (url: string) => {
        openExternalLink(url)
    }
    return (
        <Card
            className={classes.newsCard}
            onClick={() => {
                handleLinkClick(permaLink ?? "")
            }}
            width="100%"
        >
            {imageUrl && (
                <CardMedia
                    component="img"
                    alt={subject}
                    style={{ borderRadius: 5 }}
                    image={imageUrl}
                    title={subject}
                    height={130}
                    className={classes.newsCardMedia}
                />
            )}
            <Typography variant="h4" className={classes.newsCardTitle}>
                {subject}
            </Typography>
        </Card>
    )
}

type UserCardFactoryProps = {
    card: UserCard
    showSurveyModal: (survey: IUserSurveyDetails["content"]) => void
}
export function UserCardFactory(props: UserCardFactoryProps) {
    const { card, showSurveyModal } = props

    switch (card.type) {
        case UserCards.CHECK_IN_REMINDER:
            return <CheckInReminderCard {...card.checkInReminderDetails} />
        case UserCards.USER_SURVEY:
            return <UserSurveyCard card={card} showSurveyModal={showSurveyModal} />
        case UserCards.SUMMARY_NUMBER:
            return <SummaryNumberCard {...card.summaryNumberDetails} />
        case UserCards.LUNCH_SUBSCRIPTION:
            return <LunchSubscriptionCard {...card.lunchSubscriptionDetails} />
        case UserCards.EXTERNAL_LINK:
            return <ExternalLinkCard {...card.externalLinkDetails} />
        case UserCards.NEWS_ITEM:
            return <UserNewsCard {...card.newsItemDetails} />
        default:
            return null
    }
}
interface IUserCardSectionsProps {
    sections: IUserCardsSection[]
    containerClassName?: string
}

export function UserCardSections(props: IUserCardSectionsProps) {
    const { sections, containerClassName } = props
    const classes = useStyles({})
    const { UserSurveyModalAdapter, showSurveyModal } = useUserSurveyModal()

    return sections.length ? (
        <>
            {sections.map((section: IUserCardsSection) => {
                return (
                    <Section key={section.id} title={section.title} className={containerClassName}>
                        <div className={classes.cardCarousel}>
                            {section.groups.map((group: IUserCardGroup, index: number) => {
                                return (
                                    <CardCarousel key={`group_${index}`}>
                                        {group.cards.map((card: UserCard) => {
                                            return (
                                                <UserCardFactory
                                                    key={card.id}
                                                    card={card}
                                                    showSurveyModal={showSurveyModal}
                                                />
                                            )
                                        })}
                                    </CardCarousel>
                                )
                            })}
                        </div>
                    </Section>
                )
            })}
            <UserSurveyModalAdapter />
        </>
    ) : null
}

export default React.memo(UserCardSections)
